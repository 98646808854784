<div class="container">
    <mat-card style="margin-top: 120px !important; margin-bottom: 120px !important">
      <div mat-card-header>
        <h4 style="text-align: center; font-weight: 600; margin-top: 20px">
          Fast Delivery
        </h4>
      </div>
      <mat-card-content>
        <form [formGroup]="fastDeliveryEntryForm" (ngSubmit)="onSubmit()">
          <div class="mt-4" fxLayout="column" fxLayoutGap="8px">
            <div
              fxLayout="column"
              fxLayout.gt-xs="row"
              fxLayoutGap="8px"
              fxLayoutGap.gt-xs="24px"
            >
              <!-- Duration Field -->
              <mat-form-field fxFlex="auto" appearance="outline">
                <mat-label>Duration</mat-label>
                <input
                  cdkFocusInitial
                  formControlName="duration"
                  matInput
                  required
                />
                <mat-error *ngIf="fastDeliveryEntryForm.get('duration')?.invalid && fastDeliveryEntryForm.get('duration')?.touched">
                  Duration is required
                </mat-error>
              </mat-form-field>

              <mat-form-field fxFlex="auto" appearance="outline">
                <mat-label>Duration Type</mat-label>
                <input
                  cdkFocusInitial
                  formControlName="durationType"
                  matInput
                  required
                />
                <mat-error *ngIf="fastDeliveryEntryForm.get('durationType')?.invalid && fastDeliveryEntryForm.get('durationType')?.touched">
                  Duration Type is required
                </mat-error>
              </mat-form-field>

              <mat-form-field fxFlex="auto" appearance="outline">
                <mat-label>Amount</mat-label>
                <input
                  cdkFocusInitial
                  formControlName="amount"
                  matInput
                  required
                />
                <mat-error *ngIf="fastDeliveryEntryForm.get('amount')?.invalid && fastDeliveryEntryForm.get('amount')?.touched">
                  Amount is required
                </mat-error>
              </mat-form-field>

              <mat-form-field fxFlex="auto" appearance="outline">
                <mat-label>Delivery Radius</mat-label>
                <input
                  cdkFocusInitial
                  formControlName="deliveryRadius"
                  matInput
                  required
                />
                <mat-error *ngIf="fastDeliveryEntryForm.get('deliveryRadius')?.invalid && fastDeliveryEntryForm.get('deliveryRadius')?.touched">
                    Delivery Radius is required
                </mat-error>
              </mat-form-field>
            </div>
          </div>
  
          <!-- Submit Button -->
          <div fxLayout="row" fxLayoutAlign="end center" class="mt-4">
            <button mat-raised-button color="primary" [disabled]="fastDeliveryEntryForm.invalid">
              Submit
            </button>
          </div>
        </form>
      </mat-card-content>
    </mat-card>
  </div>
  