import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FastDeliveryService } from '../../service/fast-delivery.service';

@Component({
  selector: 'app-timeslot',
  templateUrl: './timeslot.component.html',
  styleUrls: ['./timeslot.component.scss']
})
export class TimeslotComponent implements OnInit {
  timingForm: FormGroup;
  isNewTiming: boolean;

  constructor(
    private fastDeliveryService: FastDeliveryService,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<TimeslotComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.isNewTiming = !data;

    const [startTime, endTime] = this.isNewTiming || !data.timing
      ? ['', '']
      : data.timing.split(' to ');

    this.timingForm = this.fb.group({
      startTime: [startTime, [Validators.required]],
      endTime: [endTime, [Validators.required]],
      status: [this.isNewTiming ? true : data.status],
    });
  }

  ngOnInit(): void {}

  onSave() {
    if (this.timingForm.valid) {
      const farmId = localStorage.getItem('farmId');
      const { startTime, endTime, status } = this.timingForm.value;
      const timing = `${startTime} to ${endTime}`;

      this.fastDeliveryService.updateTimeSlot({
        ...this.data,
        timing,
        status,
        farmId
      }).subscribe(
        (response) => {
          this.dialogRef.close(this.timingForm.value);
        },
        (error) => {
          console.error('Error saving data:', error);
        }
      );
    }
  }

  onClose() {
    this.dialogRef.close();
  }
}
