import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-dialog',
  template: `
    <h1 mat-dialog-title>Are you sure?</h1>
    <div mat-dialog-content>
      <p>Do you really want to delete this timing slot?</p>
    </div>
    <div mat-dialog-actions>
      <button mat-button (click)="onCancel()" class="cancel-button">Cancel</button>
      <button mat-button color="warn" (click)="onConfirm()" class="delete-button">Delete</button>
    </div>
  `,
  styleUrls: ['./confirm-dialog.component.scss'], // Ensure the correct path
  encapsulation: ViewEncapsulation.None // Disable view encapsulation
})
export class ConfirmDialogComponent {
  constructor(
    private dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  onCancel(): void {
    // Close the dialog without any action
    this.dialogRef.close(false);
  }

  onConfirm(): void {
    // Close the dialog with confirmation
    this.dialogRef.close(true);
  }
}
