<h2 mat-dialog-title>{{ isNewTiming ? 'Add New Timing' : 'Edit Timing' }}</h2>
<mat-dialog-content>
  <form [formGroup]="timingForm">
    <mat-form-field>
      <mat-label>Start Time</mat-label>
      <input matInput formControlName="startTime" placeholder="Enter start time (e.g., 6:00 AM)">
      <mat-error *ngIf="timingForm.get('startTime')?.hasError('required')">Start time is required</mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>End Time</mat-label>
      <input matInput formControlName="endTime" placeholder="Enter end time (e.g., 10:00 AM)">
      <mat-error *ngIf="timingForm.get('endTime')?.hasError('required')">End time is required</mat-error>
    </mat-form-field>

    <mat-checkbox matInput formControlName="status">Active</mat-checkbox>
  </form>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button (click)="onClose()">Cancel</button>
  <button mat-button [disabled]="!timingForm.valid" (click)="onSave()">Save</button>
</mat-dialog-actions>
