import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FarmService {

  public baseUrl = environment.apiBaseUrl + '/farm';
  constructor(private http: HttpClient) { }

   getAllFarm = () => {
    return this.http.get(`${this.baseUrl}/getAll`);
  }

  getActiveFarm = () => {
    return this.http.get(`${this.baseUrl}/active`);
  }

  saveFarm = (data) => {
    return this.http.post(`${this.baseUrl}/save`,data);
  }

  updateFarm = (data) => {
    return this.http.patch(`${this.baseUrl}/update`,data);
  }

  deleteFarm = (id) => {
    var params = new HttpParams().set('id', id);
    return this.http.delete(`${this.baseUrl}/delete`, { params });
  }

  updateFarmStatus = (data) => {
    return this.http.put(`${this.baseUrl}/status`,data);
  }

  getFarmByFarmCircleId = (data) => {
    return this.http.post(`${this.baseUrl}/byFarmCircle`,data);
  }

  getfarmDetailsById = (id) => {
    var params = new HttpParams().set('id', id);
    return this.http.get(`${this.baseUrl}/getFarmDetailsById`, { params });
  }

  updateFarmOnlineStatus(data){
    return this.http.put(`${this.baseUrl}/updateFarmOnlineStatus`,data);
  }

  updateFarmCodStatus(data){
    return this.http.put(`${this.baseUrl}/updateFarmCodStatus`,data);
  }

  changeDeliveryCharge(data) {
    return this.http.put(`${this.baseUrl}/changeDeliveryCharge`,data);
  }
}
