import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Layout2Component } from './layout2.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { RouterModule } from '@angular/router';
import { ProgressBarModule } from 'src/@vex/components/progress-bar/progress-bar.module';
import { SearchModule } from 'src/@vex/components/search/search.module';
import { FooterComponent } from './components/footer/footer.component';
import { ToolbarComponent } from './components/toolbar/toolbar.component';
import { ToolbarUserComponent } from './components/toolbar-user/toolbar-user.component';
import { ToolbarUserDropdownComponent } from './components/toolbar-user-dropdown/toolbar-user-dropdown.component';
import { NavigationComponent } from './components/navigation/navigation.component';
import { NavigationItemComponent } from './components/navigation-item/navigation-item.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { IconModule } from '@visurel/iconify-angular';
import { MaterialModule } from 'src/app/material/material.module';
import { SidenavComponent } from './components/sidenav/sidenav.component';
import { SidenavItemComponent } from './components/sidenav-item/sidenav-item.component';
import { ScrollbarComponent } from './components/scrollbar/scrollbar.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';



@NgModule({
  declarations: [Layout2Component, FooterComponent, ToolbarComponent, ToolbarUserComponent, ToolbarUserDropdownComponent, NavigationComponent, NavigationItemComponent, SidenavComponent, SidenavItemComponent, ScrollbarComponent],
  imports: [
    CommonModule,
    RouterModule,
    MatSidenavModule,
    ProgressBarModule,
    SearchModule,
    MaterialModule,
    IconModule,
    FlexLayoutModule,
    FontAwesomeModule
  ], exports: [Layout2Component, FooterComponent, ToolbarComponent, ToolbarUserComponent, ToolbarUserDropdownComponent, NavigationComponent, NavigationItemComponent, SidenavComponent, SidenavItemComponent, ScrollbarComponent],
  entryComponents: [ToolbarUserDropdownComponent]
})
export class Layout2Module { }
