import { coerceBooleanProperty } from "@angular/cdk/coercion";
import { Platform } from "@angular/cdk/platform";
import { DOCUMENT } from "@angular/common";
import { Component, Inject, LOCALE_ID, Renderer2 } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Settings } from "luxon";
import { map, filter } from "rxjs/operators";
import { ConfigName } from "src/@vex/interfaces/config-name.model";
import { ConfigService } from "src/@vex/services/config.service";
import { LayoutService } from "src/@vex/services/layout.service";
import { NavigationService } from "src/@vex/services/navigation.service";
import { SplashScreenService } from "src/@vex/services/splash-screen.service";
import { StyleService, Style } from "src/@vex/services/style.service";
import icTable from "@iconify/icons-ic/round-table-view";
import icCategory from "@iconify/icons-ic/round-category";
import {
  faBalanceScale,
  faCity,
  faCog,
  faCompress,
  faCrow,
  faCrown,
  faImages,
  faLeaf,
  faMobileAlt,
  faNotesMedical,
  faParachuteBox,
  faPeopleArrows,
  faSeedling,
  faShoppingBag,
  faShoppingCart,
  faSitemap,
  faStickyNote,
  faStore,
  faStoreAlt,
  faStoreSlash,
  faTachometerAlt,
  faTags,
  faTicketAlt,
  faTruckLoading,
  faTruckMonster,
  faTruckMoving,
  faTruckPickup,
  faUser,
  faUserAlt,
  faUserTie
} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent {
  title = "farm2bagadmincli";

  constructor(
    private configService: ConfigService,
    private styleService: StyleService,
    private renderer: Renderer2,
    private platform: Platform,
    @Inject(DOCUMENT) private document: Document,
    @Inject(LOCALE_ID) private localeId: string,
    private layoutService: LayoutService,
    private route: ActivatedRoute,
    private navigationService: NavigationService,
    private splashScreenService: SplashScreenService
  ) {
    Settings.defaultLocale = this.localeId;

    if (this.platform.BLINK) {
      this.renderer.addClass(this.document.body, "is-blink");
    }

    /**
     * Customize the template to your needs with the ConfigService
     * Example:
     *  this.configService.updateConfig({
     *    sidenav: {
     *      title: 'Custom App',
     *      imageUrl: '//placehold.it/100x100',
     *      showCollapsePin: false
     *    },
     *    footer: {
     *      visible: false
     *    }
     *  });
     */

    /**
     * Config Related Subscriptions
     * You can remove this if you don't need the functionality of being able to enable specific configs with queryParams
     * Example: example.com/?layout=apollo&style=default
     */
    this.route.queryParamMap
      .pipe(
        map(
          queryParamMap =>
            queryParamMap.has("rtl") &&
            coerceBooleanProperty(queryParamMap.get("rtl"))
        )
      )
      .subscribe(isRtl => {
        this.document.body.dir = isRtl ? "rtl" : "ltr";
        this.configService.updateConfig({
          rtl: isRtl
        });
      });

    this.route.queryParamMap
      .pipe(filter(queryParamMap => queryParamMap.has("layout")))
      .subscribe(queryParamMap =>
        this.configService.setConfig(queryParamMap.get("layout") as ConfigName)
      );

    this.route.queryParamMap
      .pipe(filter(queryParamMap => queryParamMap.has("style")))
      .subscribe(queryParamMap =>
        this.styleService.setStyle(queryParamMap.get("style") as Style)
      );

    /**
     * Add your own routes here
     */

    this.navigationService.items = [
      {
        type: "link",
        label: "Dashboard",
        route: "/farm2bag/dashboard",
        icon: faTachometerAlt,
        routerLinkActiveOptions: { exact: true }
      },
      {
        type: "link",
        label: "Category",
        route: "/farm2bag/master/productCategory",
        icon: faSitemap,
        routerLinkActiveOptions: { exact: true }
      },
      {
        type: "link",
        label: "Product",
        route: "/farm2bag/product1/product",
        icon: faNotesMedical,
        routerLinkActiveOptions: { exact: true }
      },
      {
        type: "link",
        label: "Farm Product",
        route: "/farm2bag/product1/farmProductData",
        icon: faNotesMedical,
        routerLinkActiveOptions: { exact: true }
      },
      {
        type: "link",
        label: "FarmCircle",
        route: "/farm2bag/farmCircle",
        icon: faTachometerAlt,
        routerLinkActiveOptions: { exact: true }
      },
      {
        type: "link",
        label: "Unit",
        route: "/farm2bag/master/unit",
        icon: faBalanceScale,
        routerLinkActiveOptions: { exact: true }
      },
      {
        type: "link",
        label: "Pincode",
        route: "/farm2bag/master/pincode",
        icon: faCity,
        routerLinkActiveOptions: { exact: true }
      },
      {
        type: "link",
        label: "Relationship Manager",
        route: "/farm2bag/master/relationshipManager",
        icon: faPeopleArrows,
        routerLinkActiveOptions: { exact: true }
      },
      {
        type: "link",
        label: "Delivery Charge",
        route: "/farm2bag/master/deliveryCharge",
        icon: faTruckMoving,
        routerLinkActiveOptions: { exact: true }
      },
      {
        type: "link",
        label: "Delivery Person Charge",
        route: "/farm2bag/master/deliveryPersonCharge",
        icon: faTruckLoading,
        routerLinkActiveOptions: { exact: true }
      },
      {
        type: "link",
        label: "Coupon",
        route: "/farm2bag/master/coupon",
        icon: faTags,
        routerLinkActiveOptions: { exact: true }
      },
      {
        type: "link",
        label: "Banner",
        route: "/farm2bag/master/banner",
        icon: faImages,
        routerLinkActiveOptions: { exact: true }
      },
      // {
      //   type: 'link',
      //   label: 'Customer',
      //   route: '/farm2bag/customer/customer',
      //   icon: faUserTie,
      //   routerLinkActiveOptions: { exact: true },
      // },
      {
        type: "dropdown",
        label: "CRM",
        icon: faCompress,
        children: [
          {
            type: "link",
            label: "Customer",
            route: "/farm2bag/customer/customer"
          },
          {
            type: "link",
            label: "Tickets",
            route: "/farm2bag/crm/tickets",
            icon: faTicketAlt,
            routerLinkActiveOptions: { exact: true }
          }
          // {
          //   type: 'link',
          //   label: 'Customer Networking',
          //   route: '/farm2bag/customer/customer',
          // },
          // {
          //   type: "link",
          //   label: "Sales and Marketing",
          //   route: "/farm2bag/customer/message"
          // }
          // {
          //   type: 'link',
          //   label: 'Messaging',
          //   route: '/farm2bag/customer/message',
          // },
        ]
      },
      {
        type: "dropdown",
        label: "Accounting",
        icon: faCompress,
        children: [
          {
            type: "link",
            label: "Credit/Debit Entries",
            route: "/farm2bag/accounting/"
          },
          {
            type: "link",
            label: "Accounting Report",
            route: "/farm2bag/accounting/accountingReport"
          },
          {
            type: "link",
            label: "Accounting Master",
            route: "/farm2bag/master/accountingMaster"
          },
          {
            type: "link",
            label: "Credit/Debit Master",
            route: "/farm2bag/master/creditDebitMaster"
          },
          {
            type: "link",
            label: "Sub Credit/Debit Master",
            route: "/farm2bag/master/subCreditDebitMaster"
          },
          {
            type: "link",
            label: "Account No Master",
            route: "/farm2bag/master/accountNoMaster"
          }
        ]
      },
      {
        type: "dropdown",
        label: "Content Mangement",
        icon: faCompress,
        children: [
          {
            type: "link",
            label: "Content Management",
            route: "/farm2bag/content/contentmanagement"
          }
        ]
      },
      {
        type: "dropdown",
        label: "Label Design",
        icon: faPeopleArrows,
        children: [
          {
            type: "link",
            label: "Label Design",
            route: "/farm2bag/label"
          }, 
          {
            type: "link",
            label: "Label Image",
            route: "/farm2bag/label/newImage"
          },
        ]
      },
      {
        type: "dropdown",
        label: "Logistics",
        icon: faCompress,
        children: [
          {
            type: "link",
            label: "Logistics",
            route: "/farm2bag/logistics"
          },
          {
            type: "link",
            label: "Logistics Report",
            route: "/farm2bag/logistics/logisticsReport"
          },
          {
            type: "link",
            label: "Tracking",
            route: "/farm2bag/logistics/tracking"
          }
        ]
      },
      {
        type: "link",
        label: "Bpo",
        route: "/farm2bag/bpo",
        icon: faLeaf,
        routerLinkActiveOptions: { exact: true }
      },
      {
        type: "link",
        label: "Message Template",
        route: "/farm2bag/master/messageTemplate",
        icon: faSitemap,
        routerLinkActiveOptions: { exact: true }
      },
      {
        type: "dropdown",
        label: "Account",
        icon: faCompress,
        children: [
          {
            type: "link",
            label: "Role",
            route: "/farm2bag/account/role"
          },
          {
            type: "link",
            label: "User",
            route: "/farm2bag/account/user"
          },
          {
            type: "link",
            label: "Module",
            route: "/farm2bag/account/module"
          }
          // {
          //   type: "link",
          //   label: "Sub Module",
          //   route: "/farm2bag/account/subModule"
          // }
        ]
      }
    ];

    this.navigationService.items2 = [
      {
        type: "link",
        label: "Dashboard",
        route: "/farm2bag/dashboard",
        icon: faTachometerAlt,
        routerLinkActiveOptions: { exact: true }
      },
      {
        type: "link",
        label: "Category",
        route: "/farm2bag/master/productCategory",
        icon: faSitemap,
        routerLinkActiveOptions: { exact: true }
      },
      {
        type: "link",
        label: "Product",
        route: "/farm2bag/product1/product",
        icon: faNotesMedical,
        routerLinkActiveOptions: { exact: true }
      },
      {
        type: "link",
        label: "Farm Hub",
        route: "/farm2bag/farm",
        icon: faStoreAlt,
        routerLinkActiveOptions: { exact: true }
      },
      {
        type: "link",
        label: "Farmer",
        route: "/farm2bag/farmer",
        icon: faSeedling,
        routerLinkActiveOptions: { exact: true }
      },
      {
        type: "link",
        label: "Delivery Person",
        route: "/farm2bag/farmCircle/deliveryPerson",
        icon: faTruckLoading,
        routerLinkActiveOptions: { exact: true }
      },
      {
        type: "link",
        label: "Coupon",
        route: "/farm2bag/master/coupon",
        icon: faTags,
        routerLinkActiveOptions: { exact: true }
      },
      {
        type: "link",
        label: "Vendor",
        route: "/farm2bag/master/vendor",
        icon: faUserAlt,
        routerLinkActiveOptions: { exact: true }
      },
      {
        type: "link",
        label: "Product Type",
        route: "/farm2bag/master/productType",
        icon: faLeaf,
        routerLinkActiveOptions: { exact: true }
      }
    ];

    this.navigationService.items3 = [
      {
        type: "link",
        label: "Dashboard",
        route: "/farm-layout/dashboard",
        icon: faTachometerAlt,
        routerLinkActiveOptions: { exact: true }
      },

      {
        type: "dropdown",
        label: "Product",
        icon: faCompress,
        children: [
          {
            type: "link",
            label: "Category",
            route: "/farm-layout/master/productCategory",
            icon: faSitemap,
            routerLinkActiveOptions: { exact: true }
          },
          {
            type: "link",
            label: "Product",
            route: "/farm-layout/product1/product",
            icon: faNotesMedical,
            routerLinkActiveOptions: { exact: true }
          },
          {
            type: "link",
            label: "Farm Product",
            route: "/farm-layout/product1/farmProduct",
            icon: faLeaf,
            routerLinkActiveOptions: { exact: true }
          },
          {
            type: "link",
            label: "Product Type",
            route: "/farm-layout/master/productType",
            icon: faLeaf,
            routerLinkActiveOptions: { exact: true }
          }
        ]
      },
      {
        type: "link",
        label: "Farmer",
        route: "/farm-layout/farmer",
        icon: faSeedling,
        routerLinkActiveOptions: { exact: true }
      },
      {
        type: "link",
        label: "Vendor",
        route: "/farm-layout/master/vendor",
        icon: faUserAlt,
        routerLinkActiveOptions: { exact: true }
      },
      {
        type: "link",
        label: "Order",
        route: "/farm-layout/order",
        icon: faShoppingBag,
        routerLinkActiveOptions: { exact: true }
      },
      {
        type: "link",
        label: "Purchase",
        route: "/farm-layout/purchase/purchase",
        icon: faShoppingCart,
        routerLinkActiveOptions: { exact: true }
      },
      {
        type: "dropdown",
        label: "Accounting",
        icon: faCompress,
        children: [
          {
            type: "link",
            label: "Credit/Debit Entries",
            route: "/farm-layout/accounting/"
          },
          {
            type: "link",
            label: "Accounting Report",
            route: "/farm-layout/accounting/hup-accountingReport"
          }
        ]
      },
      {
        type: "link",
        label: "Stock",
        route: "/farm-layout/stock/stock",
        icon: faStore,
        routerLinkActiveOptions: { exact: true }
      },
      {
        type: "link",
        label: "Bin",
        route: "/farm-layout/master/bin",
        icon: faStore,
        routerLinkActiveOptions: { exact: true }
      },
      {
        type: "dropdown",
        label: "Report",
        icon: faCog,
        children: [
          {
            type: "link",
            label: "Order Report",
            route: "/farm-layout/order/orderReport",
            icon: faStore,
            routerLinkActiveOptions: { exact: true }
          },
          {
            type: "link",
            label: "Farm Product Report",
            route: "/farm-layout/report/product",
            icon: faStore,
            routerLinkActiveOptions: { exact: true }
          },
          {
            type: "link",
            label: "Purchase Report",
            route: "/farm-layout/report/purchase",
            icon: faStore,
            routerLinkActiveOptions: { exact: true }
          },
          {
            type: "link",
            label: "Stock Report",
            route: "/farm-layout/report/stockReport",
            icon: faStore,
            routerLinkActiveOptions: { exact: true }
          }
        ]
      },
      {
        type: "dropdown",
        label: "Inventory",
        icon: faCompress,
        children: [
          {
            type: "link",
            label: "Vendor",
            route: "/farm-layout/inventory/vendor",
            icon: faStore,
            routerLinkActiveOptions: { exact: true }
          },
          {
            type: "link",
            label: "Vendor Rate",
            route: "/farm-layout/inventory/vendor-rate",
            icon: faStore,
            routerLinkActiveOptions: { exact: true }
          },
          {
            type: "link",
            label: "GST",
            route: "/farm-layout/master/gst",
            icon: faStore,
            routerLinkActiveOptions: { exact: true }
          },
          {
            type: "link",
            label: "Purchase",
            route: "/farm-layout/inventory/purchase",
            icon: faStore,
            routerLinkActiveOptions: { exact: true }
          },
          {
            type: "link",
            label: "Entry",
            route: "/farm-layout/inventory/entry",
            icon: faStore,
            routerLinkActiveOptions: { exact: true }
          },
          {
            type: "link",
            label: "QC-Check",
            route: "/farm-layout/inventory/qc",
            icon: faStore,
            routerLinkActiveOptions: { exact: true }
          },
          {
            type: "link",
            label: "Store",
            route: "/farm-layout/inventory/store",
            icon: faStore,
            routerLinkActiveOptions: { exact: true }
          },
          {
            type: "link",
            label: "location-master",
            route: "/farm-layout/master/location",
            icon: faStore,
            routerLinkActiveOptions: { exact: true }
          }
        ]
      }
    ];
    this.navigationService.items5 = [
      {
        type: "link",
        label: "BPO Dashboard",
        route: "/farm2bag/bpoDashboard",
        icon: faTachometerAlt,
        routerLinkActiveOptions: { exact: true }
      },
      {
        type: "link",
        label: "Customer",
        route: "/farm2bag/bpoDashboard/bpocustomers",
        icon: faUserTie,
        routerLinkActiveOptions: { exact: true }
      }
    ];
    this.navigationService.items6 = [
      {
        type: "link",
        label: "Dashboard",
        route: "/farm2bag/crm/dashboard",
        icon: faTachometerAlt,
        routerLinkActiveOptions: { exact: true }
      },
      {
        type: "link",
        label: "Customers",
        route: "/farm2bag/crm/customers",
        icon: faUserTie,
        routerLinkActiveOptions: { exact: true }
      },
      {
        type: "link",
        label: "Tickets",
        route: "/farm2bag/crm/tickets",
        icon: faTicketAlt,
        routerLinkActiveOptions: { exact: true }
      },
      {
        type: "link",
        label: "Sales and Marketing",
        route: "/farm2bag/crm/message",
        icon: faMobileAlt
      }
    ];
  }
}
