<div *ngIf="loginType === 'FarmCircle'" #originRef [routerLink]="['/farm2bag/farm']" routerLinkActive="router-link-active"  [class.bg-hover]="dropdownOpen"
  class="flex items-center rounded cursor-pointer relative trans-ease-out select-none py-1 pr-1 pl-3 hover:bg-hover"
  matRipple>

  <div class="rounded-full h-9 w-9 flex items-center justify-center text-primary bg-primary-light">
    <mat-icon [icIcon]="icPerson"></mat-icon>
  </div>
  <div class="body-1 font-medium leading-snug ltr:mr-3 rtl:ml-3" fxHide.xs> <span>Back to
      Admin</span></div>
</div>
  <!-- <div #originRef
     (click)="showPopover(originRef)"
     [class.bg-hover]="dropdownOpen"
     class="flex items-center rounded cursor-pointer relative trans-ease-out select-none py-1 pr-1 pl-3 hover:bg-hover"
     matRipple *ngIf="loginType === 'FarmHub'"> -->

     <div #originRef
     class="flex items-center rounded py-1 pr-1 pl-3 "
     matRipple *ngIf="loginType === 'FarmHub'">

     <div class="rounded-full h-9 w-9 flex items-center justify-center text-primary">
      <mat-icon [icIcon]="icPerson"></mat-icon>
    </div>
  <div class="body-1 font-medium leading-snug ltr:mr-3 rtl:ml-3" fxHide.xs>{{storeName | uppercase}}</div>

  <!-- <div class="body-1 font-medium leading-snug ltr:mr-3 rtl:ml-3" fxHide.xs>{{storeName}}
    <span></span></div> -->
  <!-- <div class="rounded-full h-9 w-9 flex items-center justify-center text-primary bg-primary-light">

    <mat-icon [icIcon]="icPerson"></mat-icon>
  </div> -->

  <div>
    <button>
      <a (click)="close()" [routerLink]="['/login']" color="primary" mat-button>LOGOUT</a>
    </button>
  </div>

</div>
