import { Component, ElementRef, HostBinding, Input, OnInit } from '@angular/core';
import { map } from 'rxjs/operators';
import { MegaMenuComponent } from 'src/@vex/components/mega-menu/mega-menu.component';
import { PopoverService } from 'src/@vex/components/popover/popover.service';
import { ConfigService } from 'src/@vex/services/config.service';
import { LayoutService } from 'src/@vex/services/layout.service';
import { NavigationService } from 'src/@vex/services/navigation.service';
import { User } from 'src/app/account-management/model/user';
import icBookmarks from '@iconify/icons-ic/twotone-bookmarks';
import star from '@iconify/icons-ic/outline-star';
import emojioneDE from '@iconify/icons-emojione/flag-for-flag-germany';
import icMenu from '@iconify/icons-ic/twotone-menu';
import icPersonAdd from '@iconify/icons-ic/twotone-person-add';
import icAssignmentTurnedIn from '@iconify/icons-ic/twotone-assignment-turned-in';
import icBallot from '@iconify/icons-ic/twotone-ballot';
import icDescription from '@iconify/icons-ic/twotone-description';
import icAssignment from '@iconify/icons-ic/twotone-assignment';
import icReceipt from '@iconify/icons-ic/twotone-receipt';
import icDoneAll from '@iconify/icons-ic/twotone-done-all';
import icArrowDropDown from '@iconify/icons-ic/twotone-arrow-drop-down';
import icCart from '@iconify/icons-ic/shopping-cart';

import { ActivatedRoute } from '@angular/router';

import icNotificationsActive from '@iconify/icons-ic/twotone-notifications-active';
import { Farm } from 'src/app/farm/model/farm';
import { FarmService } from 'src/app/farm/service/farm.service';
import { FastDelivery } from '../../models/fast-delivery';
import { FastDeliveryService } from '../../service/fast-delivery.service';
import { error } from '@angular/compiler/src/util';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit {
  @Input() mobileQuery!: boolean;
  @Input() user!: User;
  @Input()
  @HostBinding('class.shadow-b')
  hasShadow!: boolean;
  @Input() farmDetails: Farm;
  public id;
  @Input() loginType: string;
  public fastDeliveryDetails: FastDelivery;

  navigationItems = this.navigationService.items;

  isHorizontalLayout$ = this.configService.config$.pipe(map(config => config?.layout === 'horizontal'));
  isVerticalLayout$ = this.configService.config$.pipe(map(config => config?.layout === 'vertical'));
  isNavbarInToolbar$ = this.configService.config$.pipe(map(config => config?.navbar.position === 'in-toolbar'));
  isNavbarBelowToolbar$ = this.configService.config$.pipe(map(config => config?.navbar.position === 'below-toolbar'));

  icCart = icCart;
  icBookmarks = icBookmarks;
  star = star;
  emojioneDE = emojioneDE;
  icMenu = icMenu;
  icPersonAdd = icPersonAdd;
  icAssignmentTurnedIn = icAssignmentTurnedIn;
  icBallot = icBallot;
  icDescription = icDescription;
  icAssignment = icAssignment;
  icReceipt = icReceipt;
  icDoneAll = icDoneAll;
  icArrowDropDown = icArrowDropDown;
  icNotificationsActive = icNotificationsActive;


  constructor(private layoutService: LayoutService,private storeService: FarmService,
              private configService: ConfigService,
              private navigationService: NavigationService,
              private popoverService: PopoverService,
              private fastDeliveryService: FastDeliveryService) {
               }

  ngOnInit() {
    this.getFastDelivery()
  }

  getFastDelivery(){
    const details = { farmId: localStorage.getItem('farmId') }
    this.fastDeliveryService.getFastDelivery(details).subscribe((res:any)=>{
        this.fastDeliveryDetails = res.result
    },(error)=>{
      console.log(error)
    })
  }

  openQuickpanel() {
    this.layoutService.openQuickpanel();
  }

  openSidenav() {
    this.layoutService.openSidenav();
  }
  


  openMegaMenu(origin: ElementRef | HTMLElement) {
    this.popoverService.open({
      content: MegaMenuComponent,
      origin,
      position: [
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'top'
        },
        {
          originX: 'end',
          originY: 'bottom',
          overlayX: 'end',
          overlayY: 'top',
        },
      ]
    });
  }

  openSearch() {
    this.layoutService.openSearch();
  }

  changeStatus(event: MatSlideToggleChange){
    const details = { farmId: localStorage.getItem('farmId') }
    this.fastDeliveryService.updateStatus({farmId:details.farmId,status:event.checked}).subscribe((res:any)=>{
    },(error)=>{
      console.log(error)
    })
  }

}


