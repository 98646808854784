import { ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { PopoverService } from "src/@vex/components/popover/popover.service";

import { ToolbarUserDropdownComponent } from "../toolbar-user-dropdown/toolbar-user-dropdown.component";
import icPerson from "@iconify/icons-ic/twotone-person";

import { Router } from "@angular/router";
import { RsaService } from "src/app/shared/service/rsa.service";
import { LoginService } from "src/app/auth/service/login.service";

@Component({
  selector: "app-toolbar-user",
  templateUrl: "./toolbar-user.component.html",
  styleUrls: ["./toolbar-user.component.scss"]
})
export class ToolbarUserComponent implements OnInit {
  dropdownOpen: boolean;
  @Input() users: any;
  icPerson = icPerson;
  @Input() loginType: string;
  public storeName;

  constructor(
    private popover: PopoverService,
    private router: Router,
    private cd: ChangeDetectorRef,
    private rsaService: RsaService,
    private loginService: LoginService
  ) {}

  ngOnInit() {
    this.storeName = this.rsaService.decrypt(localStorage.getItem("2"));
  }

  showPopover(originRef: HTMLElement) {
    this.dropdownOpen = true;
    this.cd.markForCheck();
    const popoverRef = this.popover.open({
      content: ToolbarUserDropdownComponent,
      origin: originRef,
      offsetY: 12,
      data: this.storeName,
      position: [
        {
          originX: "center",
          originY: "top",
          overlayX: "center",
          overlayY: "bottom"
        },
        {
          originX: "end",
          originY: "bottom",
          overlayX: "end",
          overlayY: "top"
        }
      ]
    });

    popoverRef.afterClosed$.subscribe(() => {
      this.dropdownOpen = false;
      this.cd.markForCheck();
    });
  }
  close() {
    console.log("here logout");
    this.loginService.logout().subscribe(
      (data: any) => {},
      err => {
        console.log(err);
      }
    );

    // this.popoverRef.close();
    this.router.navigateByUrl("");
    localStorage.clear();
  }
}
