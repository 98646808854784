import { Component, Input, OnInit } from '@angular/core';
import { map } from 'rxjs/operators';
import { ConfigService } from 'src/@vex/services/config.service';
import { LayoutService } from 'src/@vex/services/layout.service';
import { NavigationService } from 'src/@vex/services/navigation.service';
import { trackByRoute } from 'src/@vex/utils/track-by';
import icRadioButtonChecked from '@iconify/icons-ic/twotone-radio-button-checked';
import icRadioButtonUnchecked from '@iconify/icons-ic/twotone-radio-button-unchecked';
import { RsaService } from 'src/app/shared/service/rsa.service';
// import { Store } from 'src/app/store/models/store';
@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit {

  @Input() collapsed?: boolean;
  @Input() storeDetails!: any;
  public view: boolean | undefined;
  collapsedOpen$ = this.layoutService.sidenavCollapsedOpen$;
  title$ = this.configService.config$.pipe(map(config => config?.sidenav.title));
  imageUrl$ = this.configService.config$.pipe(map(config => config?.sidenav.imageUrl));
  imageUrl2$ = this.configService.config$.pipe(map(config => config?.sidenav.imageUrl2));
  showCollapsePin$ = this.configService.config$.pipe(map(config => config?.sidenav.showCollapsePin));

  items = this.navigationService.items3;
  trackByRoute = trackByRoute;
  icRadioButtonChecked = icRadioButtonChecked;
  icRadioButtonUnchecked = icRadioButtonUnchecked;

  constructor(private navigationService: NavigationService,
              public layoutService: LayoutService,
              private configService: ConfigService,
              private rsaService: RsaService) {
              }

  ngOnInit() {
  }

  onMouseEnter() {
    this.layoutService.collapseOpenSidenav();
    this.view = true;
  }

  onMouseLeave() {
    this.layoutService.collapseCloseSidenav();
    this.view = false;
  }

  toggleCollapse() {
    this.collapsed ? this.layoutService.expandSidenav() : this.layoutService.collapseSidenav();
  }

}
