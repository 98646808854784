import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class RsaService {

  encryptMode: boolean | undefined;
  textToConvert: string | undefined;
  password = 'Shine@321';
  conversionOutput: string | undefined;

  constructor() { }

  encrypt = (textToConvert: string) => {
    return this.conversionOutput = CryptoJS.AES.encrypt(textToConvert.trim(), this.password.trim()).toString();
  }

  decrypt = (textToConvert: string ) => {
    return this.conversionOutput = CryptoJS.AES.decrypt(textToConvert.trim(), this.password.trim()).toString(CryptoJS.enc.Utf8);
  }
}
