import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { VexModule } from 'src/@vex/vex.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthHtppInterceptorServiceService } from './shared/service/auth-htpp-interceptor-service.service';
import { CustomLayoutModule } from './custom-layout/custom-layout.module';
import { FarmLayoutModule } from './farm-layout/farm-layout.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { PurchaseDetailPopupComponent } from './purchase/components/purchase-detail-popup/purchase-detail-popup.component';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { CreditDebitEntryDetailsComponent } from './accounting/components/credit-debit-entry-details/credit-debit-entry-details.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';



@NgModule({
  declarations: [
    AppComponent,
    PurchaseDetailPopupComponent,
    CreditDebitEntryDetailsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FontAwesomeModule,
    VexModule,
    HttpClientModule,
    CustomLayoutModule,
    FarmLayoutModule,
    CommonModule,
    RouterModule  ,
    MatDialogModule,
    MatButtonModule,// Add RouterModule to the imports array
  ],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: AuthHtppInterceptorServiceService, multi: true }],
  bootstrap: [AppComponent]
})
export class AppModule { }
